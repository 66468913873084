/**
 * Ввод купона в корзине
 * @param {Object} options
 */
$.fn.basketCoupon = function(options) {
    const $widget = $(this);

    /** Настройки плагина */
    const settings = $.extend({
        getAppliedCouponStateUrl : '',
        selectors :
            {
                applyForm : '.js-apply-coupon-form',
                clearForm : '.js-clear-coupon-form',
                container : '.js-coupon-container',
                codeInput : '.js-code-input',
                applyButton : '.js-coupon-apply-btn',
                clearButton : '.js-coupon-clear-btn',
                errorText : '.js-error-text',
                successText : '.js-success-text',
            },
        classes : {
            error : 'has-error',
            success : 'has-success',
        }
    }, options);

    const errorClass = settings.classes.error;
    const successClass = settings.classes.success;

    const $applyForm = $widget.find(settings.selectors.applyForm);
    const $clearForm = $widget.find(settings.selectors.clearForm);
    const $container = $applyForm.find(settings.selectors.container);
    const $codeInput = $applyForm.find(settings.selectors.codeInput);
    const $applyButton = $applyForm.find(settings.selectors.applyButton);
    const $clearButton = $applyForm.find(settings.selectors.clearButton);
    const $errorText = $applyForm.find(settings.selectors.errorText);
    const $successText = $applyForm.find(settings.selectors.successText);
    const $btnPromoCodeClear = $applyForm.find('.js-promo-code-clear');
    const $blockPromoCode = $widget.find('.js-block-promo-code');
    const $closedBlockPromoCode = $widget.find('.js-closed-block-promo-code');
    const $btnCloseBlockPromoCode = $widget.find('.js-btn-close-block');

    /**
     * Вернуть форму в исходное состояние
     */
    const clearState = () => {
        $container.removeClass(errorClass);
        $container.removeClass(successClass);

        $errorText.html('');
        $successText.html('');
    };

    /**
     * Перевести форму в состояние ошибки
     * @param {string} errorMessage
     */
    const setStateError = (errorMessage) => {
        $container.addClass(errorClass);
        if (errorMessage) {
            $errorText.html(errorMessage);
        }
    };

    /**
     * Перевести форму в успешное состояние
     * @param {string} successMessage
     */
    const setStateSuccess = (successMessage) => {
        $container.addClass(successClass);
        if (successMessage) {
            $successText.html(successMessage);
        }

    };

    /**
     * Заблокировать форму
     */
    const lockForm = () => {
        $applyButton.attr('disabled', true);
        $clearButton.attr('disabled', true);
    };

    /**
     * Разблокировать форму
     */
    const unlockForm = () => {
        $applyButton.attr('disabled', false);
        $clearButton.attr('disabled', false);
    };

    /** Форма купона была только что обновлена */
    this.justUpdated = false;

    /**
     * Обработчик события отправления формы
     * @param {Event} event
     */
    const applyCoupon = (event) => {
        event.preventDefault();

        clearState();
        lockForm();

        $.ajax({
            url : $applyForm.attr('action'),
            method : $applyForm.attr('method'),
            data : $applyForm.serialize(),
        })
         .done((response) => {
             if (response.success) {
                 setStateSuccess(response.successMessage);
             } else {
                 setStateError(response.error);
             }
             this.justUpdated = true;
             BasketApi.updateBasketInfo();
             unlockForm();
         })
         .fail(() => {
             unlockForm();
             $.errorHandler.showError('Ошибка применения купона');
         });
    };

    const clearCoupon = (event) => {
        event.preventDefault();
        lockForm();

        $.ajax({
            url : '/basket/clear-coupon',
            method : 'POST',
            data : $applyForm.find('input[name="_csrf"]').val(),
        })
         .done((response) => {
             this.justUpdated = true;
             clearState();
             BasketApi.updateBasketInfo();
             unlockForm();
         })
         .fail(() => {
             unlockForm();
             $.errorHandler.showError('Ошибка отмены купона');
         });
    }

    /**
     *
     */
    const updateCouponFormState = () => {
        if (this.justUpdated) {
            this.justUpdated = false;
            return;
        }

        clearState();
        lockForm();

        $.ajax({
            url : settings.getAppliedCouponStateUrl,
            method : 'GET',
            cache : false,
        })
         .done((response) => {
             unlockForm();
             if (!response.message || !response.code) {
                 return;
             }

             if (response.success) {
                 setStateSuccess(response.message);
                 $btnPromoCodeClear.show();
             } else {
                 setStateError(response.message);
             }

             $codeInput.val(response.code);
         })
         .fail(() => {
             unlockForm();
             $.errorHandler.showError('Ошибка обновления состояния купона');
         });
    };

    const openBlockPromoCode = () => {
        $blockPromoCode.removeClass('closed');
    };

    const closeBlockPromoCode = () => {
        $blockPromoCode.addClass('closed');
    };

    //Появление крестика, при заполнении поля
    const showBtnClear = () => {
        $codeInput.on('input',() => {
            if (!$codeInput.val()) {
                $btnPromoCodeClear.hide()
            } else {
                $btnPromoCodeClear.show()
            }
        });
    }

    //Клик на крестик. Очистка поля ввода
    $btnPromoCodeClear.on('click', () => {
        $codeInput.val('');
        clearState();
        $btnPromoCodeClear.hide()
    });

    /**
     * Инициализация обработчиков событий
     */
    const initEventHandlers = () => {
        $applyForm.on('submit', applyCoupon);
        $clearButton.on('click', clearCoupon);
        $(document).on('basket-updated', updateCouponFormState);
        $(document).on('basket-cleaned', updateCouponFormState);
        $closedBlockPromoCode.on('click', openBlockPromoCode );
        $btnCloseBlockPromoCode.on('click', closeBlockPromoCode)
    };

    const construct = () => {
        initEventHandlers();
        updateCouponFormState();
        showBtnClear();
    };

    construct();


    return this;
};
